import ExecutionEnvironment from 'exenv';

import type { Item, OrderInfo, Price } from 'types/orderByPurchaseId';
import {
  addTransactionItem,
  createTransaction,
  CustomVarIndex,
  CustomVarKey,
  sendTransaction,
  setQuery,
  trackCustomVariable,
  trackPageview
} from 'utils/contentSquare/uxaHelper';

type PriceProperties = 'total';
type ItemProperties = 'title' | 'productId' | 'asin' | 'price' | 'itemQuantity';

// Make required properties (Required<Pick>), the rest are optional (Partial)
type EcommOrderInfo = Pick<OrderInfo, 'orderId'> & {
  price: Partial<Price> & Pick<Price, PriceProperties>;
  items: Partial<Item> & Pick<Item, ItemProperties>[];
};

export interface EcommOrderConfirmation {
  orderInfo: EcommOrderInfo[];
}

export const trackArtificialPageViewModalOpen = ({ query, pageType }: { query: string; pageType?: string }) => {
  if (ExecutionEnvironment.canUseDOM) {
    const { location } = window;
    // order matters custom variables need set before the `trackPageView` gets added
    if (pageType) {
      trackCustomVariable({
        index: CustomVarIndex.PAGE_TYPE,
        key: CustomVarKey.PAGE_TYPE,
        value: pageType
      });
    }
    setQuery(location.search + (location.search ? '&' : '?') + query);
    trackPageview(location);
    setQuery(location.search);
  }
};

export const trackEcommOrderConfirmation = ({ orderInfo }: EcommOrderConfirmation) => {
  orderInfo.forEach(({ orderId, items, price: { total } }: EcommOrderInfo) => {
    createTransaction({ id: orderId, revenue: total, currency: 'USD' });
    items.forEach(({ title, productId, asin, itemQuantity, price }) => {
      addTransactionItem({ id: productId, sku: asin, price, name: title, quantity: itemQuantity });
    });
    sendTransaction();
  });
};
