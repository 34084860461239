import { useSelector } from 'react-redux';

import { FEATURE_CHECKOUT_KRATOS } from 'constants/features';
import { selectHasKratosCheckoutCookie } from 'selectors/cookies';
import useMartyContext from 'hooks/useMartyContext';
import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';

export const useKratosCheckoutEnabled = () => {
  const { marketplace } = useMartyContext();
  const { isEnrolled } = useIsEnrolledInVariant(FEATURE_CHECKOUT_KRATOS);
  const hasKratosCheckoutCookie = useSelector(selectHasKratosCheckoutCookie);

  return marketplace.hasKratosCheckout && (hasKratosCheckoutCookie || isEnrolled);
};
