import AddToBagDrawer from 'components/cart/AddToBagDrawer';
import CartModal from 'components/cart/CartModal';

export interface CartModalOrDrawerProps {
  isOpen: boolean;
}

function CartModalOrDrawer({ isOpen }: CartModalOrDrawerProps) {
  const isKratosCheckout = false;

  if (!isOpen) {
    return null;
  }

  return isKratosCheckout ? <AddToBagDrawer open /> : <CartModal />;
}

export default CartModalOrDrawer;
