import { type ComponentProps, forwardRef } from 'react';
import { Link } from 'react-router-dom';

export interface HrefLinkProps extends ComponentProps<typeof Link> {
  href: string;
}

const HrefLink = forwardRef<HTMLAnchorElement, HrefLinkProps>((props, ref) => {
  const { href, ...forwardProps } = props;

  return <Link {...forwardProps} to={href} ref={ref} />;
});

HrefLink.displayName = 'HrefLink';

export default HrefLink;
