import useMartyContext from 'hooks/useMartyContext';
import { useIsEnrolledInVariant } from 'hooks/unleash/useIsEnrolledInVariant';
import { FEATURE_KRATOS_CHECKOUT_INIT } from 'constants/features';

export const useKratosCheckoutInitExperimentEnabled = () => {
  const { marketplace } = useMartyContext();
  const { isEnrolled } = useIsEnrolledInVariant(FEATURE_KRATOS_CHECKOUT_INIT);

  return marketplace.hasKratosCheckout && isEnrolled;
};
